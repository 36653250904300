import crudGetters from "@/store/templates/crud/getters";
import { isNil } from "lodash";

export default {
  deliveryMethod: state => state.model,
  id: state => state.model.id,
  companyId: state => state.model.companyId,
  name: state => state.model.name,
  type: state => state.model.type,
  recipients: state =>
    !isNil(state.model.recipients)
      ? state.model.recipients.map(
          contactPersonId => state.contactPersons.entity[contactPersonId]
        )
      : null,
  contactPersons: state =>
    !isNil(state.contactPersons.ids)
      ? state.contactPersons.ids.map(
          contactPersonId => state.contactPersons.entity[contactPersonId]
        )
      : null,
  ...crudGetters
};
