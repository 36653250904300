import crudMutations from "@/store/templates/crud/mutations";

export default {
  setName(state, Name) {
    state.model.name = Name;
  },
  setType(state, Type) {
    state.model.type = Type;
  },
  setRecipients(state, Recipients) {
    state.model.recipients = Recipients;
  },
  setCompanyId(state, CompanyId) {
    state.model.companyId = CompanyId;
  },
  ...crudMutations
};
