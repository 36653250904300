import initialCrudState from "@/store/templates/crud/state";

const initialModel = () => ({
  id: null,
  companyId: null,
  name: null,
  type: "MAIL",
  recipients: null
});

export default () => ({
  ...initialCrudState(initialModel),
  contactPerson: {}
});
